<template>
  <v-app>
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

// import { DefaultApolloClient } from '@vue/apollo-composable'
// import { ApolloClient, InMemoryCache } from '@apollo/client/core'
// import { provide } from 'vue-demi'

export default {
  name: 'App',
  setup () {
    // gql initialization
    // const cache = new InMemoryCache()
    // const apolloClient = new ApolloClient({
    //   cache,
    //   uri: 'http://localhost:1337/graphql'
    // })
    // provide(DefaultApolloClient, apolloClient)
  },
  provide: {
    strapiUrl: "https://backend.partymachinery.com"
  }
}
</script>
