import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Gallery from '../views/Gallery.vue'
import Fullpage from '../views/Fullpage.vue'
import Photo from '../views/Photo.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/gallery/:id',
    name: 'gallery',
    component: Gallery
  },
  {
    path: '/photo/:id',
    name: 'photo',
    component: Photo
  },
  {
    path: '/fullpage',
    name: 'fullpage',
    component: Fullpage
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
