<template>
  <div>
    <section>
      <div class="bg-white">
        <!-- FIXME: maybe there is a better solution to width problem? -->

        <!-- <div class="mx-auto flex flex-col justify-center">
          <swiper-container :slidesPerView="2" :spaceBetween="0" :freeMode="false" :loop="true" :pagination="{
            clickable: true,
          }">
            <swiper-slide>
              <div class="mx-auto">
                <img :src="require('../assets/quizmaster_1.jpg')" />

              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="">
                <img :src="require('../assets/photobooth_1.jpg')" />

              </div>
            </swiper-slide>
          </swiper-container>
        </div> -->

        <div class="flex flex-col  w-screen items-center justify-center">
          <div>
            <img class="max-w-full max-h-full" :src="require('../assets/quizmaster_1.jpg')" />
          </div>
        </div>

        <!-- <div class="flex flex-col w-screen relative">
          <div class="flex flex-wrap max-w-7xl w-screen h-auto justify-between items-center my-20 mx-auto">
            <div class="flex flex-col justify-center">
              <h1 class="text-5xl">Quizmaster</h1>
              <ul>
                <li>Geld einwerfen um Spiel zu starten</li>
                <li>Personalisierte Fragen beantworten</li>
                <li>Bei Beantwortung aller Fragen Preis entnehmen</li>
              </ul>
            </div>
            <img class="" :src="require('../assets/quizmaster-outline.png')" />
          </div>

          <Pricing price="500 €" />
          <div class="flex h-auto max-w-6xl mx-auto justify-center">
            <div class="m-10">
              <v-form ref="requestForm" v-model="valid">
                <v-text-field v-model="name" :rules="nameRules" label="Name" required></v-text-field>

                <v-text-field v-model="email" :rules="emailRules" label="E-Mail" required></v-text-field>

                <v-text-field v-model="telephone" :rules="telephoneRules" label="Telefonnummer" required></v-text-field>

                <v-switch v-model="machines" label="Quizmaster" value="quiz" hide-details></v-switch>

                <v-switch v-model="machines" :rules="[testRules]" label="Photobooth" value="photo"></v-switch>

                <v-checkbox v-model="checkbox" :rules="agbRules" label="Ich bin mit den AGB einverstanden"
                  required></v-checkbox>
                <v-btn color="black" text="white" class="" @click="validate">
                  Unverbindliche Anfrage
                </v-btn>
              </v-form>
            </div>
          </div>

        </div> -->
      </div>
    </section>
  </div>
</template>

<script setup>
// Import Swiper Vue.js components
import { register } from 'swiper/element/bundle';
import Pricing from "@/components/Pricing.vue";

import axios from "axios";

import { ref, watch, inject } from "vue";

register();

// get strapi url from global variable
const strapiUrl = inject("strapiUrl");

const center = { lat: 51.093048, lng: 6.84212 };
const markers = [
  {
    position: {
      lat: 51.093048,
      lng: 6.84212,
    },
  },
];

const show = ref(true);
const hover = ref(true);

const valid = ref(false);
const name = ref("");
const nameRules = [(v) => !!v || "Dieses Feld ist verpflichtend"];
const email = ref("");
const emailRules = [
  (v) => !!v || "Dieses Feld ist verpflichtend",
  (v) => /.+@.+\..+/.test(v) || "Bitte korrekte E-Mail Adresse eingeben",
];

const telephone = ref("");
const telephoneRules = [
  (v) => !!v || "Dieses Feld ist verpflichtend",
  (v) => /\+{0,1}[0-9]+/.test(v) || "Bitte korrekte Telefonnummer eingeben",
];

const date = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
  .toISOString()
  .substring(0, 10);

const checkbox = ref(false);

const machines = ref([]);
const switchRules = [
  (v) => !!v || "Bitte wählen sie einen oder mehrere Automaten aus",
];
const agbRules = [(v) => !!v || "Dieses Feld ist verpflichtend"];

function testRules() {
  if (machines.value.length == 0) {
    return "Bitte wählen sie einen oder mehreren Automaten aus";
  } else {
    return true;
  }
}
const requestForm = ref(null);

async function validate() {
  // check request form
  const validation = await requestForm.value.validate();

  if (validation.valid) {
    const nameVal = name.value;
    const telephoneVal = telephone.value;
    const emailVal = email.value;
    const data = JSON.stringify({
      data: {
        // FIXME
        date: "2022-01-01",
        quizmaster: machines.value.includes("quiz"),
        photobooth: machines.value.includes("photo"),
      },
    });

    var config = {
      method: "post",
      url: strapiUrl + "/api/enquiries",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        const enquiryId = response.data.data.id;
        createCustomer(enquiryId, nameVal, emailVal, telephoneVal);
      })
      .catch(function (error) {
        console.log(error);
      });
    requestForm.value.reset();
  }
}

async function createCustomer(id, name, email, telephone) {
  const data = JSON.stringify({
    data: {
      name: name,
      email: email,
      telephone: telephone,
      enquiry: id,
    },
  });

  var config = {
    method: "post",
    url: strapiUrl + "/api/customers",
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  axios(config)
    .then(function (response) {
      console.log(JSON.stringify(response.data));
    })
    .catch(function (error) {
      console.log(error);
    });
  requestForm.value.reset();
}
</script>

<style scoped></style>
