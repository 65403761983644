import { createApp } from 'vue'
// import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import VueGoogleMaps from '@fawmi/vue-google-maps'
import VueFullPage from 'vue-fullpage.js'

import './index.css'

loadFonts()

// Vue.prototype.$hostname = 'localhost:5000'

const app = createApp(App)
  .use(router)
  .use(store)
  .use(vuetify)
  .use(VueFullPage)
  .use(VueGoogleMaps, {
    load: {
      key: 'AIzaSyDHBkM2dq5qFNPnZFUMUXaERFAIVIoDvOk'
    },
  }).mount('#app')

  
