<template>
  <div class="flex items-center justify-center h-screen">
    <img :src="strapiUrl + photo.image[0].url">
  </div>
</template>

<script setup>
import axios from "axios"
import { useRoute } from "vue-router"
import { inject, computed } from "vue"

const strapiUrl = inject("strapiUrl")

const route = useRoute()
const photo = await axios.get(strapiUrl + "/api/photos/" + route.params.id)
  .then((response) => {
    return response.data.data.attributes.results[0]
  })

</script>

<style>
</style>
