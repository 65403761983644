<template>
  <Suspense>
  <Photo />
  </Suspense>
</template>

<script>
import Photo from '@/components/Photo.vue'

export default {
  name: 'PhotoComponent',
  components: {
    Photo
  }
}
</script>

