<template>
  <div class="max-w-5xl mx-auto">
    <div class="font-light text-5xl py-12 text-center">
      <p>
        {{ gallery.photobooth.event.pretty_name }}
      </p>
    </div>
    <div :id="galleryId">
      <div class="grid grid-cols-2 gap-3 place-items-center">
        <div v-for="(photo, key) in filteredPhotos" :key="photo.id">
          <v-lazy>
        <a :href="strapiUrl + photo.image[0].url"
          :data-pswp-width="photo.image[0].width" :data-pswp-height="photo.image[0].height" target="_blank"
          rel="noreferrer">
          <img :src="strapiUrl + photo.image[0].formats.small.url" alt="" />
        </a>
        </v-lazy>
        </div>
        
      </div>
    </div>

  </div>
</template>

<script setup>
import axios from "axios"
import { useRoute } from "vue-router"
import { inject, computed, onMounted } from "vue"
import PhotoSwipeLightbox from "photoswipe/lightbox"
import "photoswipe/style.css"

const strapiUrl = inject("strapiUrl")

const galleryId = "lgGallery"
const route = useRoute()
const gallery = await axios.get(strapiUrl + "/api/galleries/" + route.params.id)
  .then((response) => {
    return response.data.data.attributes.results[0]
  })

onMounted(() => {
  const lightbox = new PhotoSwipeLightbox({
    gallery: "#lgGallery",
    children: "a",
    pswpModule: () => import("photoswipe"),
  });
  lightbox.init();
})

const filteredPhotos = computed(() => gallery.photos.filter((photo) => photo.type === "ORIGINAL"))

</script>

<style></style>
