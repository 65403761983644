<template>
  <!-- <NavigationBar /> -->
  <HeroSection />
</template>

<script>
import { defineComponent } from 'vue'

// import HeroSection from '../components/HeroSection.vue'
import HeroSection from '../components/HeroSection.vue'
import NavigationBar from '../components/NavigationBar.vue'

export default defineComponent({
  name: 'HomeView',

  components: {
    HeroSection,
    NavigationBar
  }
})
</script>
