<template>
  <Suspense>
  <Gallery />
  </Suspense>
</template>

<script>
import Gallery from '@/components/Gallery.vue'

export default {
  name: 'GalleryComponent',
  components: {
    Gallery
  }
}
</script>
